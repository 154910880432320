import React from "react";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { userDetail } from "Stores/userDetail";
import { Utils } from "Utils/utils";
import WelcomePopupLottie from "Utils/LottieFiles/welcomePopupLottie.json";
import { Button } from "Components/common/Button";
import { ReactComponent as WelcomeSvg } from "Assets/svg/initialWelcomePopup.svg";
import onboarding from "./Onboarding";

class InitialWelcomePopupState {
  @observable showPopup = false;
  @observable restrictOnboardingPopup = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setRestrictOnboardingPopup = val => {
    this.restrictOnboardingPopup = val;
  };
}

const initialWelcomePopupState = new InitialWelcomePopupState();

const InitialWelcomePopup = props => {
  const {
    userInfo: {
      name: userName = "",
      firstName: userFirstName = "",
      lastName: userLastName = ""
    } = {}
  } = userDetail;
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WelcomePopupLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const handleLetsGoBtnClick = () => {
    initialWelcomePopupState.setShowPopup(false);
    initialWelcomePopupState.setRestrictOnboardingPopup(true);
    onboarding.setPopup(true);
    if (window?.Intercom?.booted) {
      setTimeout(() => {
        return Utils.initiateIntercom("emailAccount");
      }, 1200);
    } else {
      setTimeout(() => {
        return Utils.initiateDriver(false, "emailAccount");
      }, 3000);
    }
  };

  const closeAction = () => {
    initialWelcomePopupState.setShowPopup(false);
  };

  const updatedName = userFirstName || userLastName || userName;
  const capitalizedName = updatedName
    ? updatedName?.replace(/^./, str => str.toUpperCase())
    : "";

  return (
    initialWelcomePopupState.showPopup && (
      <section className="initialWelcomePopupWrapper">
        <div className="card">
          <div
            className="popupClose"
            title="Click here to close"
            onClick={closeAction}
          >
            <i className="material-icons">close</i>
          </div>
          <header className="welcomePopupHeader">
            <h3 className="userName">
              {`Welcome to Salesgear${userName ? ", " : " "}`}
              <span className="orangeText">{`${
                userName ? `${capitalizedName || ""}` : ""
              }`}</span>
              !
            </h3>
            <WelcomeSvg />
            <p className="headerPopupText">
              You just earned <span className={"orangeText"}>100 credits</span>{" "}
              for your first 14 days with us.
            </p>
            <p className="headerAdditionalText">
              {`Start automating LinkedIn, calls and emails to generate real replies and grow your pipeline.`}
            </p>
          </header>
          <footer className="welcomePopupFooter">
            <Button
              id={"letsGoBtn"}
              name="letsGoBtn"
              type="button"
              className="letsGoBtn"
              btnText="Let's Go"
              icon={true}
              iconName="arrow_right_alt"
              loader={false}
              click={handleLetsGoBtnClick}
            />
          </footer>
        </div>
        <div className="common-popup-mask" />
      </section>
    )
  );
};

export { InitialWelcomePopup, initialWelcomePopupState };
export default observer(InitialWelcomePopup);
