import React from "react";
import { upgradePopupState } from "../Components/Paywall/UpgradePopup";

export const PaywallUtils = {};

//get planName
PaywallUtils.getPlanName = (
  planName = "",
  inFreePlan = false,
  isHierachy = false,
  appendText = "Plan"
) => {
  if (isHierachy && inFreePlan) {
    return `basic${appendText}`;
  } else if (
    inFreePlan ||
    ["basic", "starter"].includes(planName?.toLowerCase())
  ) {
    return `pro${appendText}`;
  } else if (["pro", "growth"].includes(planName?.toLowerCase())) {
    return `unlimited${appendText}`;
  } else if (["unlimited"].includes(planName?.toLowerCase())) {
    return `enterprise${appendText}`;
  }
};

PaywallUtils.getUpgradePopupPlanName = (
  planName = "",
  inFreePlan = false,
  trialEnabled = false,
  isHierachy = false,
  appendText = "Plan"
) => {
  if (trialEnabled && !inFreePlan) {
    return `free trial`;
  } else if (isHierachy && inFreePlan) {
    return `Free ${appendText}`;
  } else if (
    inFreePlan ||
    ["basic", "starter"].includes(planName?.toLowerCase())
  ) {
    return `Basic ${appendText}`;
  } else if (["pro", "growth"].includes(planName?.toLowerCase())) {
    return `Pro ${appendText}`;
  } else if (["unlimited"].includes(planName?.toLowerCase())) {
    return `Unlimited ${appendText}`;
  }
};

PaywallUtils.upgradeUtilsFunction = (
  type = "",
  isHierachy = false,
  planNameValue = "",
  inFreePlan = false,
  objProp = {},
  hasReturn = false
) => {
  let obj = {};
  let returnPlanName = "";

  if (type) {
    // Default object for next plan mapping
    const planObj = {
      free: {
        planName: "Basic",
        exisitingPlanName: "Free"
      },
      basic: {
        planName: "Pro",
        exisitingPlanName: inFreePlan ? "Free" : planNameValue
      },
      pro: { planName: "Unlimited", exisitingPlanName: planNameValue },
      unlimited: { planName: "Enterprise", exisitingPlanName: planNameValue }
    };

    // Else if with plan name
    if (isHierachy && inFreePlan) {
      obj = planObj["free"];
      returnPlanName = "basicPlan";
    } else if (
      (isHierachy &&
        ["basic", "starter"].includes(planNameValue?.toLowerCase())) ||
      (!isHierachy &&
        (inFreePlan ||
          ["basic", "starter"].includes(planNameValue?.toLowerCase())))
    ) {
      obj = planObj["basic"];
      returnPlanName = "proPlan";
    } else if (["pro", "growth"].includes(planNameValue?.toLowerCase())) {
      obj = planObj["pro"];
      returnPlanName = "unlimitedPlan";
    } else if (planNameValue?.toLowerCase() === "unlimited") {
      obj = planObj["unlimited"];
      returnPlanName = "enterprisePlan";
    }

    // Construct new obj property
    if (Object.keys(objProp)?.length > 0) {
      Object.keys(objProp).map(key => {
        obj[key] = objProp[key];
      });
    }
    obj.type = type;
    upgradePopupState.setPopupValues(obj);

    // Return value enabled
    if (hasReturn) {
      return returnPlanName;
    } else {
      upgradePopupState.setPopup(true);
    }
  }
};

export default PaywallUtils;
