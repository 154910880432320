/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import ChangeShareType from "Components/common/ChangeShareType";
import SequenceTemplatePopup from "./../SequenePredefinedTemplate/SequenceTemplatePopup";
import { Link, withRouter, useLocation } from "react-router-dom";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import { userDetail } from "Stores/userDetail";
import { sequenceStoreData } from "Stores/sequenceData";
import Utils from "Utils/utils";
import ConstantObj from "Static/constant";
import { ReactComponent as DeleteIcon } from "Assets/svg/delete.svg";
import { ReactComponent as CloneIcon } from "Assets/svg/clone.svg";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { URLS, makeApi } from "Utils/apiURL";
import PaywallUtils from "Utils/PaywallUtils";
import { MP_EVENT } from "Static/constant";
import { toasterState } from "Components/common/toaster";
import { settingsStoreData } from "Stores/settingsData";
import SortIcon from "Components/common/SortIcon";
import { tagsStoreData } from "Stores/TagsData";
import { addSingleContactPopupState } from "Components/common/AddSingleContactPopup";
import AddContactDropDown from "Components/common/AddContactDropDown";
import SequenceUtils from "Utils/SequenceUtils";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { scheduleDataStore } from "Stores/ScheduleData";
import UpgradeSequenceCard from "Components/Paywall/UpgradeSequenceCard";
import { SEQ_BACKGROUND_MSG } from "Model/model";
import CustomTooltip from "Components/common/CustomTooltip";
import { ActivateSequenceToggle } from "./ActivateSequence";

let cloneSeqFlag = false;
let cloneSeqId = "";
let defaultSeqType = ConstantObj.sequenceType.private;
let sharedSeq = ConstantObj.sequenceType.shared.type;

const SequenceTable = props => {
  const {
    data,
    isShared,
    sortSequence,
    sortIconStatus,
    activeSort,
    taskEnabled = "",
    showUpgrade = false
  } = props || {};
  const {
    noOfSequencesEligible = 0,
    inFreePlan = false,
    planName = "",
    noOfContactsEligible = 0,
    noOfContactsUsed = 0
  } = userDetail?.userFeatureFlag || {};

  let userData = userDetail.userFeatureFlag;
  let settingsData = settingsStoreData.settingsData;
  const [showCreateSeqLoader, setCreateSeqLoader] = useState(false);

  let sequenceData = data;

  const [newSequenceType, setNewSequenceType] = useState(defaultSeqType);
  const [updatedSequenceName, setUpdatedSequenceName] = useState("");

  const remainingContactCount = () => {
    return noOfContactsEligible - noOfContactsUsed;
  };

  // Sequence creation flow Start
  const newSequenceNameAction = event => {
    if (event !== undefined && event !== null) {
      const { value } = event.currentTarget;
      setUpdatedSequenceName(value);
    }
  };

  const newSeqNameFocus = e => {
    e && e.target.select();
  };

  const newSeqNameCancelAction = event => {
    Utils.preventDefaultFn(event);
    setNewSequenceType("");
    props.setCreateSeq(!props.showCreateSeq);
    props.setNewSeqName("New Sequence");
    if (sequenceData && !Object.keys(sequenceData)?.length) {
      props.setNoSequence(true);
    }
  };

  const closePredefinedSequencePopup = event => {
    Utils.preventDefaultFn(event);
    props.setCreateSeqPopup(!props.showCreateSeqPopup);
    props.setNewSeqName("New Sequence");
    if (sequenceData && !Object.keys(sequenceData)?.length) {
      props.setNoSequence(true);
    }
  };

  const editSeqCancelAction = (e, seqId) => {
    const updatedSeqData = showHideEditView(seqId);
    sequenceStoreData.setSequenceData(updatedSeqData);
    props.setSequenceData(updatedSeqData);
  };

  const saveNewSeqName = async (event, seqId, seqName) => {
    Utils.preventDefaultFn(event);
    if (showUpgrade) {
      PaywallUtils.upgradeUtilsFunction(
        "newSequence",
        false,
        planName,
        inFreePlan,
        { sequenceCount: noOfSequencesEligible }
      );
    } else {
      if (seqName?.length) {
        updatedSequenceName?.length === 0 && setUpdatedSequenceName(seqName);
        if (updatedSequenceName?.length && !showCreateSeqLoader) {
          if (cloneSeqFlag) {
            cloneSeqFlag = false;
            cloneSequence(updatedSequenceName);
          } else {
            const {
              steps: duplicateSteps = {},
              settings = {},
              scheduleWindowId = "",
              inboxIds = []
            } = Utils.findCurrentObj(sequenceData, seqId) || {};
            const payload = constructNewSeqPayload(
              settings,
              scheduleWindowId,
              inboxIds,
              updatedSequenceName,
              duplicateSteps,
              null
            );
            try {
              setCreateSeqLoader(true);
              setNewSequenceType("");
              const response = await saveSequence(payload);
              if (response && response.status === 200 && response.data) {
                Utils.mixpanelTrack(MP_EVENT.SQ_CREATED, {
                  sequenceName: updatedSequenceName,
                  sequenceType: "Clone",
                  pageType: "Sequence Page"
                });
                userData.noOfSequencesUsed += 1;
                userDetail.setUserFeatureFlag(userData);
                handleSaveSequenceSuccess(response);
                setCreateSeqLoader(false);
              } else if (response.response.status === 426) {
                Utils.showUpgradePopup("sequences", true, true);
                setCreateSeqLoader(false);
                props.setCreateSeq(false);
              } else {
                handleSaveSequenceFailure(response);
                setCreateSeqLoader(false);
                props.setCreateSeq(false);
              }
            } catch (e) {
              handleSaveSequenceFailure();
              setCreateSeqLoader(false);
              props.setCreateSeq(false);
            }
          }
        } else {
          document.getElementById("newseqName").classList.add("errorShown");
        }
      } else {
        toasterState.setToastMsg("Sequence name is required", "fail");
      }
    }
  };

  const handleSaveSequenceSuccess = response => {
    if (response?.data) {
      sequenceData.unshift(response.data);
      sequenceStoreData.setSequenceData(sequenceData);
      props.setSequenceData(sequenceData);
      props.setCreateSeq(false);
      toasterState.setToastMsg("Sequence created successfully", "success");
      redirectInsideSequence(response.data);
    }
  };

  const redirectInsideSequence = data => {
    if (data?.id) {
      const sequenceURL = getSequnceURL(data);
      if (sequenceURL && props && props.history) {
        setTimeout(() => {
          props.history.push(sequenceURL);
        }, 500);
      }
    }
  };

  const getSequnceURL = data => {
    if (data?.id) {
      const folderId = data.folderId;
      const sequenceId = data.id;
      let sequenceUrl = "/sequences";
      if (folderId) {
        sequenceUrl += `/${folderId}`;
        if (sequenceId) {
          sequenceUrl += `/${sequenceId}`;
        }
      } else if (sequenceId) {
        sequenceUrl += `/all/${sequenceId}`;
      }
      return sequenceUrl;
    } else {
      return null;
    }
  };

  const handleSaveSequenceFailure = response => {
    props.setCreateSeq(false);
    Utils.showApiFailureResponse(response);
  };

  const updateSequenceCbk = async (payload, seqId) => {
    try {
      setCreateSeqLoader(true);
      setNewSequenceType("");
      const response = await saveSequence(payload);
      const responseStatus = response?.status;
      setUpdatedSequenceName("");
      if (responseStatus && responseStatus === 200 && response?.data) {
        handleUpdateSuccess(response.data, seqId);
        setCreateSeqLoader(false);
      } else if (response?.response?.status) {
        const respStatus = response.response.status;
        setCreateSeqLoader(false);
        closeorOpenEditOverlay(seqId);
        if (respStatus === 403) {
          toasterState.setToastMsg(
            "You don’t have editing access to this sequence",
            "warning"
          );
        } else if (respStatus === 426) {
          Utils.showAccountSuspendedPopup();
        }
      } else {
        handleSaveSequenceFailure(response);
        setCreateSeqLoader(false);
      }
    } catch (e) {
      handleSaveSequenceFailure();
      setCreateSeqLoader(false);
    }
  };

  const updateSequence = async (event, seqId, seqName) => {
    Utils.preventDefaultFn(event);
    // if only sequence privleage changed ,sequeunce name onchange event wont trigger.so updated the old sequnece name to the state in the below line
    updatedSequenceName.length === 0 && setUpdatedSequenceName(seqName);
    if (updatedSequenceName?.length) {
      const {
        steps: duplicateSteps = {},
        settings = {},
        scheduleWindowId = "",
        inboxIds = [],
        shareType: currentShareType = ""
      } = Utils.findCurrentObj(sequenceData, seqId) || {};
      const payload = constructNewSeqPayload(
        settings,
        scheduleWindowId,
        inboxIds,
        updatedSequenceName,
        duplicateSteps,
        seqId
      );
      if (
        !Utils.isShared(currentShareType) &&
        userData?.multipleInboxEnabled &&
        Utils.isShared(payload?.shareType)
      ) {
        confirmationPopupState.setPopupValues({
          content: `<span style="color: #343A40;">Are you sure you want to change this sequence from private to shared?</span><br/><span style='color: #8D8D8D;font-size: 14px;'>Once shared, all your teammates can access the sequence, and you cannot revert it to private.</span>`,
          actionBtnText: "Continue",
          cancelBtnText: "Cancel",
          callback: () => updateSequenceCbk(payload, seqId),
          cancelCbk: () => {
            confirmationPopupState.setShowPopup(false);
            closeorOpenEditOverlay(seqId);
            setCreateSeqLoader(false);
          }
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        updateSequenceCbk(payload, seqId);
      }
    }
  };

  const closeorOpenEditOverlay = seqId => {
    const editedSeqData = showHideEditView(seqId);
    sequenceStoreData.setSequenceData(editedSeqData);
    editedSeqData && props.setSequenceData(editedSeqData);
  };

  const handleUpdateSuccess = (updatedData, updatedSeqId) => {
    if (updatedData && updatedSeqId) {
      toasterState.setToastMsg("Sequence updated successfully", "success");
      const duplicateSeqData = JSON.parse(JSON.stringify(sequenceData));
      duplicateSeqData.map((seqObj, key) => {
        if (seqObj?.id === updatedSeqId) {
          seqObj.isEdit = false;
          seqObj.accessType = updatedData.accessType;
          seqObj.shareType = updatedData.shareType;
          seqObj.name = updatedData.name;
        }
      });
      sequenceStoreData.setSequenceData(duplicateSeqData);
      props.setSequenceData(duplicateSeqData);
    }
  };

  const saveSequence = payload => {
    const config = {
      url: URLS.createSequence,
      method: "POST",
      data: payload
    };
    return makeApi(config);
  };

  const constructNewSeqPayload = (
    settings,
    scheduleWindowId,
    inboxIds,
    seqName,
    cloneData,
    updatedSeqId
  ) => {
    const payload = JSON.parse(
      JSON.stringify(ConstantObj.manipulateSeqPayload)
    );
    if (updatedSeqId) {
      payload.id = updatedSeqId;
    }
    payload.type = "New Sequence";
    payload.name = seqName;
    payload.shareType = Utils.getSequenceShareType(newSequenceType);
    payload.accessType = Utils.getSequenceType(newSequenceType);
    const folderDetailsObj = Utils.getFolderDetails();
    const folderName = !isShared ? folderDetailsObj.folderName : "";
    const folderId = !isShared ? folderDetailsObj.folderId : "";
    payload.folderName = folderName || null;
    payload.folderId = folderId || null;
    if (cloneData) {
      // For cloning the sequence, the last contact added varinat should be 0
      Object.keys(cloneData).map(item => {
        cloneData[item].lastContactAddedInVariants = 0;
      });
      payload.steps = cloneData;
      payload.firstStageIsReply = Utils.getFirstEmailStatus(cloneData);
    }
    if (settings && Object.keys(settings).length) {
      payload.settings = settings;
    }
    if (scheduleWindowId) {
      payload.scheduleWindowId = scheduleWindowId;
    }
    if (inboxIds) {
      payload.inboxIds = inboxIds;
    }
    return payload;
  };
  // Sequence creation flow End

  const toggleAction = async (
    event,
    id,
    steps,
    isActiveSequence,
    redirectUrl,
    seqDataObj = {}
  ) => {
    Utils.preventDefaultFn(event);
    let isChecked = event.target.checked;
    let type = isChecked ? "RESUME" : "PAUSE";

    const toggleActionCbk = async () => {
      const currentSeq = Utils.findCurrentObj(sequenceData, id);
      if (!seqDataObj?.background?.loading) {
        updateSequenceStatus(id, seqDataObj?.background?.status, true);
        if (
          SequenceUtils.resumeSequenceEnabled(props, type, currentSeq) &&
          (await checkTeamSubscriptionActive())
        ) {
          let hasTask = SequenceUtils.hasTask(steps);
          if (
            id &&
            (!steps ||
              (steps &&
                (!hasTask ||
                  (hasTask && taskEnabled) ||
                  (isActiveSequence && hasTask))))
          ) {
            let response =
              await sequenceStoreData.getBackgroundPauseResumeSequence({
                filter: { sequenceId: id },
                type,
                totalCount: seqDataObj?.contactsAdded || 0
              });
            if (response?.data) {
              const {
                background,
                bulkOperationResult = {},
                activeJob = false
              } = response?.data || {};
              if (isChecked) {
                if (
                  bulkOperationResult?.response &&
                  Object.keys(bulkOperationResult?.response)?.length
                ) {
                  const {
                    respCode = "",
                    successCount = 0,
                    failedCount = 0
                  } = bulkOperationResult?.response || {};
                  if (activeJob) {
                    SequenceUtils.showActiveJobPopupForBulkActions();
                  } else if (
                    (+respCode === 200 &&
                      successCount === 0 &&
                      failedCount === 0) ||
                    background
                  ) {
                    updateSequenceStatus(id, type, background);
                    SequenceUtils.handleSuccessResponseForPauseResume(
                      background,
                      type
                    );
                  } else {
                    SequenceUtils.handleSuccessFailedResponseForResume(
                      props,
                      redirectUrl,
                      bulkOperationResult?.response
                    );
                    updateSequenceStatus(
                      id,
                      bulkOperationResult?.response?.successCount > 0
                        ? type
                        : "PAUSE",
                      false
                    );
                  }
                  return;
                } else {
                  if (activeJob) {
                    SequenceUtils.showActiveJobPopupForBulkActions();
                  } else {
                    updateSequenceStatus(id, type, background);
                    SequenceUtils.handleSuccessResponseForPauseResume(
                      background,
                      type
                    );
                  }
                }
              } else {
                if (
                  bulkOperationResult?.error &&
                  Object.keys(bulkOperationResult?.error)?.length
                ) {
                  SequenceUtils.handleFailedResponseForPauseResume(
                    props,
                    redirectUrl,
                    bulkOperationResult?.error
                  );
                  updateSequenceStatus(
                    id,
                    seqDataObj?.background?.status,
                    false
                  );
                  return;
                } else {
                  if (activeJob) {
                    SequenceUtils.showActiveJobPopupForBulkActions();
                  } else {
                    updateSequenceStatus(id, type, background);
                    SequenceUtils.handleSuccessResponseForPauseResume(
                      background,
                      type
                    );
                  }
                }
              }
            } else {
              SequenceUtils.handleFailedResponseForPauseResume(
                props,
                redirectUrl,
                "",
                response
              );
              updateSequenceStatus(id, seqDataObj?.background?.status, false);
            }
            return;
          } else {
            updateSequenceStatus(id, seqDataObj?.background?.status, false);
          }
          return;
        }
      }
      updateSequenceStatus(id, seqDataObj?.background?.status, false);
    };

    if (type === "RESUME") {
      toggleActionCbk();
    } else {
      confirmationPopupState.setPopupValues({
        content: `Are you sure you want to disable this sequence? All the scheduled emails and tasks from this sequence will be paused until it’s activated.`,
        actionBtnText: "Yes",
        cancelBtnText: "No",
        callback: toggleActionCbk
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const resumeCbk = (data, id) => {
    updateSequenceStatus(id, data === "true" ? "ACTIVE" : "", data === "load");
  };

  const pauseCbk = (data, id) => {
    updateSequenceStatus(id, data === "true" ? "PAUSED" : "", data === "load");
  };

  const updateSequenceStatus = (id, status, loading) => {
    const modyfiedSeq = sequenceData.map(item => {
      if (item?.id === id) {
        item.background = { status, loading };
      }
      return item;
    });
    sequenceStoreData.setSequenceData(modyfiedSeq);
    modyfiedSeq && props.setSequenceData(modyfiedSeq);
  };

  const maskAction = event => {
    Utils.preventDefaultFn(event);
    if (document.getElementsByClassName("optionEnabled").length)
      document
        .getElementsByClassName("optionEnabled")[0]
        .classList.remove("optionEnabled");
  };

  const bulkUploadFormSubmitAction = (bulkCsvData, seqId) => {
    const addTagPopupCbk = tagsList => {
      const extractedTagsArray = Utils.extractTagNameFromList(tagsList);
      showScheduleSequencePopup(seqId, bulkCsvData, extractedTagsArray);
    };
    Utils.showAddTagsPopup(addTagPopupCbk, bulkCsvData.length);
  };

  const showScheduleSequencePopup = (seqId, bulkCsvData, tagsList = []) => {
    const scheduleCbk = async () => {
      const response = await Utils.scheduleSequence(
        settingsData,
        bulkCsvData,
        seqId,
        tagsList
      );
      const {
        data: { responseCode = 500 }
      } = response || {};
      if (+responseCode === 200) {
        Utils.getOnboardingData();
        Utils.trackOnboardingMixpanel("contact_added");
        tagsStoreData.getAllTags();
        toasterState.setToastMsg("Sequence successfully scheduled", "success");
        if (tagsList && tagsList.length) {
          Utils.mixpanelTrack(MP_EVENT.TAG_ADDED, {
            pageType: window.location.pathname
          });
        }
      } else if (+responseCode === 105) {
        SequenceUtils.showNoInboxconnectedPopup();
      } else if (+responseCode === 509) {
        Utils.showContactsUploadLimitReached();
      } else {
        Utils.showApiFailureResponse(response);
      }
    };
    addBulkContactPopupState.setFileName("");
    scheduleCbk();

    document.getElementById("seqBulkPopupWrapper").style.display = "none";
    document.getElementsByClassName("seqBulkCont")[0].innerHTML = "";
  };

  const addSingleContactCbk = async id => {
    const currentSeq = Utils.findCurrentObj(sequenceData, id);
    await sequenceStoreData.getSenderToken(id);
    if (sequenceStoreData?.missingSenderTokens?.length === 0) {
      remainingContactCount() > 0
        ? Utils.showAddSingleContactToSequencePopup(
            currentSeq,
            addContactSuccessCbk
          )
        : PaywallUtils.upgradeUtilsFunction(
            "contactsAdded",
            false,
            planName,
            inFreePlan,
            { contactCount: noOfContactsEligible }
          );
    } else {
      SequenceUtils.showMissingSenderTokenPopup(props);
    }
  };

  const addSingleContact = async id => {
    maskAction();
    if (await checkTeamSubscriptionActive()) {
      const currentSeq = Utils.findCurrentObj(sequenceData, id);
      if (SequenceUtils.checkAndAllowContactUpload(props, currentSeq, id)) {
        if (SequenceUtils.checkEmailSteps(currentSeq)) {
          if (SequenceUtils.inboxConnected(currentSeq)) {
            addSingleContactCbk(id);
          } else {
            SequenceUtils.showNoInboxconnectedPopup(
              props,
              SequenceUtils.getSequencePath(currentSeq, props?.folderIdFromURL)
            );
          }
        } else {
          addSingleContactCbk(id);
        }
      }
    }
  };

  const addContactSuccessCbk = () => {
    Utils.getOnboardingData();
    Utils.trackOnboardingMixpanel("contact_added");
    tagsStoreData.getAllTags();
    toasterState.setToastMsg("Sequence successfully scheduled", "success");
  };

  const addBulkContactToSequenceCbk = async id => {
    const bulkUploadCbk = bulkCsvData => {
      bulkUploadFormSubmitAction(bulkCsvData, id);
    };
    const currentSeq = Utils.findCurrentObj(sequenceData, id);
    await sequenceStoreData.getSenderToken(id);
    if (sequenceStoreData?.missingSenderTokens?.length === 0) {
      remainingContactCount() > 0
        ? Utils.showAddBulkContactToSequencePopup(currentSeq, bulkUploadCbk)
        : PaywallUtils.upgradeUtilsFunction(
            "contactsAdded",
            false,
            planName,
            inFreePlan,
            { contactCount: noOfContactsEligible }
          );
    } else {
      SequenceUtils.showMissingSenderTokenPopup(props);
    }
  };

  const addBulkContactToSequence = async id => {
    maskAction();
    if (await checkTeamSubscriptionActive()) {
      const currentSeq = Utils.findCurrentObj(sequenceData, id);
      if (SequenceUtils.checkAndAllowContactUpload(props, currentSeq, id)) {
        if (SequenceUtils.checkEmailSteps(currentSeq)) {
          if (SequenceUtils.inboxConnected(currentSeq)) {
            addBulkContactToSequenceCbk(id);
          } else {
            SequenceUtils.showNoInboxconnectedPopup(
              props,
              SequenceUtils.getSequencePath(currentSeq, props?.folderIdFromURL)
            );
          }
        } else {
          addBulkContactToSequenceCbk(id);
        }
      }
    }
  };

  const moreOptionAction = event => {
    Utils.preventDefaultFn(event);
    const { currentTarget } = event;
    if (document.getElementsByClassName("optionEnabled").length)
      document
        .getElementsByClassName("optionEnabled")[0]
        .classList.remove("optionEnabled");
    currentTarget.classList.add("optionEnabled");
  };

  const showHideEditView = seqId => {
    const duplicateSeqData = JSON.parse(JSON.stringify(data));
    if (seqId && duplicateSeqData) {
      duplicateSeqData.map((seqObj, index) => {
        if (seqObj?.id === seqId) {
          seqObj.isEdit = !seqObj.isEdit;
          if (Utils.isShared(seqObj?.shareType)) {
            setNewSequenceType(seqObj.accessType);
          } else {
            setNewSequenceType(ConstantObj.sequenceType.private);
          }
        }
      });
      return duplicateSeqData;
    }
  };

  const editAction = async (event, id, name) => {
    if (await checkTeamSubscriptionActive()) {
      closeorOpenEditOverlay(id);
    } else {
      maskAction();
    }
  };

  const cloneAction = async (event = "", id = "", name = "", type = "") => {
    Utils.preventDefaultFn(event);
    if (await checkTeamSubscriptionActive()) {
      if (showUpgrade) {
        maskAction();
        PaywallUtils.upgradeUtilsFunction(
          "newSequence",
          false,
          planName,
          inFreePlan,
          { sequenceCount: noOfSequencesEligible }
        );
      } else {
        cloneSeqFlag = true;
        cloneSeqId = id;
        maskAction();
        props.setNewSeqName("Copy - " + name);
        setNewSequenceType(type || defaultSeqType);
        props.setCreateSeq(true);
      }
    }
  };

  function getCloneObj(obj) {
    if (obj == null || typeof obj != "object") return obj;
    let temp = new obj.constructor();
    for (let key in obj) {
      temp[key] = getCloneObj(obj[key]);
    }
    return temp;
  }

  const cloneSequenceCbk = async payload => {
    try {
      setCreateSeqLoader(true);
      const response = await saveSequence(payload);
      props.setNewSeqName("New Sequence");
      if (response?.status === 200) {
        userData.noOfSequencesUsed += 1;
        userDetail.setUserFeatureFlag(userData);
        handleSaveSequenceSuccess(response);
        setCreateSeqLoader(false);
      } else if (response?.response?.status === 426) {
        Utils.showUpgradePopup("sequences", true, true);
      } else {
        handleSaveSequenceFailure(response);
        setCreateSeqLoader(false);
      }
    } catch (e) {
      props.setNewSeqName("New Sequence");
      handleSaveSequenceFailure();
      setCreateSeqLoader(false);
    }
  };

  const cloneSequence = async cloneSeqName => {
    maskAction();
    if (cloneSeqName?.length) {
      const sequenceToClone = Utils.findCurrentObj(sequenceData, cloneSeqId);
      const clonedSequence = getCloneObj(sequenceToClone) || {};
      const {
        steps: duplicateSteps = {},
        settings = {},
        scheduleWindowId = "",
        inboxIds = []
      } = clonedSequence || {};
      if (clonedSequence) {
        const payload = constructNewSeqPayload(
          settings,
          scheduleWindowId,
          inboxIds,
          cloneSeqName,
          duplicateSteps,
          null
        );
        payload.scheduleWindowId = clonedSequence?.scheduleWindowId;
        payload.inboxIds = clonedSequence?.inboxIds;
        payload.excludeContactEnabled = clonedSequence?.excludeContactEnabled;
        if (
          userData?.multipleInboxEnabled &&
          Utils.isShared(payload?.shareType)
        ) {
          confirmationPopupState.setPopupValues({
            content: `<span style="color: #343A40;">Are you sure you want to change this sequence from private to shared?</span><br/><span style='color: #8D8D8D;font-size: 14px;'>Once shared, all your teammates can access the sequence, and you cannot revert it to private.</span>`,
            actionBtnText: "Continue",
            cancelBtnText: "Cancel",
            callback: () => cloneSequenceCbk(payload),
            cancelCbk: () => {
              confirmationPopupState.setShowPopup(false);
              props.setCreateSeq(false);
              setNewSequenceType("");
            }
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          cloneSequenceCbk(payload);
        }
      }
    }
  };

  const deleteAction = async (event, id) => {
    Utils.preventDefaultFn(event);
    maskAction();
    if (await checkTeamSubscriptionActive()) {
      const deleteConfirmationCbk = buttonLoadingFn => {
        const activeStatusCbk = () => {
          buttonLoadingFn(false);
          confirmationPopupState.setShowPopup(false);
        };

        const deleteSeqCbk = id => {
          deleteSequence(id, buttonLoadingFn);
        };

        Utils.checkSeqStatus(
          "deleteActiveStep",
          id,
          deleteSeqCbk,
          "Your sequence has active recipients. Please pause the sequence before deleting.",
          activeStatusCbk,
          true
        );
      };

      confirmationPopupState.setPopupValues({
        title: "Are you sure?",
        content: "Are you sure you want to delete this sequence?",
        actionBtnText: "Yes",
        callback: deleteConfirmationCbk,
        loadingMsg: "Please wait..."
      });

      confirmationPopupState.setShowPopup(true);
    }
  };

  const deleteSequence = async (id, stopBtnLoadingFn) => {
    try {
      maskAction();
      let targetURL = URLS.deleteSeq;
      targetURL = targetURL + id;
      const config = {
        url: targetURL,
        method: "DELETE"
      };
      let response = await makeApi(config);
      const respData = response?.data;
      if (
        respData?.requestStatus &&
        respData?.message.indexOf("Successfully deleted") !== -1
      ) {
        userData.noOfSequencesUsed -= 1;
        userDetail.setUserFeatureFlag(userData);
        stopBtnLoadingFn(false);
        confirmationPopupState.setShowPopup(false);
        SequenceUtils.removeActiveSequenceIdFromLocalStorage(id);
        deleteSucces(id);
        toasterState.setToastMsg("Sequence deleted successfully", "success");
      } else {
        stopBtnLoadingFn(false);
        confirmationPopupState.setShowPopup(false);
        Utils.showApiFailureResponse(response);
      }
    } catch (error) {
      console.error("Delete seq error ", error);
    }
  };

  const deleteSucces = id => {
    Utils.getOnboardingData();
    sequenceData.map((value, index) => {
      sequenceData[index].randomId = id;
      if (value?.id === id) {
        const temp = sequenceData.splice(index, 1);
        return sequenceData;
      }
    });
    SequenceUtils.addActiveSequenceIdToLocalStorage(sequenceData);
    const duplicateSeqData = JSON.parse(JSON.stringify(sequenceData));
    sequenceStoreData.setSequenceData(duplicateSeqData);
    if (duplicateSeqData?.length === 0) {
      props.setNoSequence(true);
      props.setSequenceData([]);
    } else {
      props.setSequenceData(duplicateSeqData);
    }
  };

  const radioOnchangeAction = (e, id = "") => {
    id && setNewSequenceType(id);
  };

  const getSequencelength = () => {
    return data?.length || 0;
  };

  useEffect(() => {
    return () => {
      addSingleContactPopupState.setShowPopup(false);
    };
  }, []);

  useEffect(() => {
    data && (sequenceData = data);
  }, [data]);

  useEffect(() => {
    settingsData = settingsStoreData.settingsData;
  }, [settingsStoreData.settingsData]);

  return (
    <div className="sectionInnerCont">
      <div
        className={`tableCont ${
          sequenceStoreData?.totalSequences >= 15
            ? "normalHeight"
            : "fullHeight"
        }`}
      >
        <div className="table" table-layout="fixed">
          <SequenceTableHeader
            cbk={sortSequence}
            sequenceLength={getSequencelength()}
            sortIconStatus={sortIconStatus}
            activeSort={activeSort}
          />
          <div className="tableBody">
            <div className="overflowWrapper">
              {props?.showCreateSeq && newSequenceType && (
                <ChangeShareType
                  save={saveNewSeqName}
                  seqNameFocus={newSeqNameFocus}
                  sequenceNameAction={newSequenceNameAction}
                  seqNameCancelAction={newSeqNameCancelAction}
                  sequenceShareType={
                    newSequenceType === "PRIVATE"
                      ? ConstantObj.sequenceType.private
                      : ConstantObj.sequenceType.shared.type
                  }
                  sequenceAccessType={
                    newSequenceType === "PRIVATE"
                      ? ConstantObj.sequenceType.shared.rw
                      : newSequenceType || ConstantObj.sequenceType.shared.rw
                  }
                  radioOnchangeAction={radioOnchangeAction}
                  showCreateSeqLoader={showCreateSeqLoader}
                  seqName={props.newSeqName ? props.newSeqName : "New Sequence"}
                  placeHolder={"Sequence Name"}
                  isClone={true}
                />
              )}
              {props?.showCreateSeqPopup && (
                <SequenceTemplatePopup
                  showUpgrade={showUpgrade}
                  seqNameCancelAction={closePredefinedSequencePopup}
                />
              )}
              <SequenceTableBody
                sequenceData={props.data}
                save={updateSequence}
                seqNameCancelAction={editSeqCancelAction}
                seqNameFocus={newSeqNameFocus}
                toggleAction={toggleAction}
                addSingleContact={addSingleContact}
                moreOptionAction={moreOptionAction}
                editAction={editAction}
                cloneAction={cloneAction}
                deleteAction={deleteAction}
                maskAction={maskAction}
                folderIdFromURL={props.folderIdFromURL}
                radioOnchangeAction={radioOnchangeAction}
                SequenceNameAction={newSequenceNameAction}
                showCreateSeqLoader={showCreateSeqLoader}
                addBulkContactToSequence={addBulkContactToSequence}
                showUpgrade={showUpgrade}
                taskEnabled={taskEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SequenceTableHeader = props => {
  const { cbk, sequenceLength, sortIconStatus, activeSort } = props;

  const sortCbk = (sortParam, sortIconStatus) => {
    cbk(sortParam, sortIconStatus);
  };

  return (
    <div className="tableHader">
      <div className="column toggleBtnHeader"></div>
      <div className="sequenceHeaderWrapper">
        <div className="column name">
          <span>Name</span>
          <SortIcon
            sequenceLength={sequenceLength}
            sortIconStatus={sortIconStatus}
            cbk={sortCbk}
            sortParam="NAME"
            isActive={activeSort}
          />
        </div>
        <div className="column contacts">Contacts</div>
        <div className="column openRate">Open Rate</div>
        <div className="column clickRate">Click Rate</div>
        <div className="column replyRate">Reply Rate</div>
        <div className="column updateTs">
          <span>Created</span>
          <SortIcon
            sequenceLength={sequenceLength}
            sortIconStatus={sortIconStatus}
            cbk={sortCbk}
            sortParam="CREATE_TS"
            isActive={activeSort}
          />
        </div>
        <div className="iconCont column">
          <div className="column icon"></div>
          <div className="column icon"></div>
        </div>
      </div>
    </div>
  );
};

const SequenceTableBody = observer(props => {
  const {
    sequenceData,
    showCreateSeqLoader,
    save,
    seqNameFocus,
    SequenceNameAction,
    seqNameCancelAction,
    radioOnchangeAction
  } = props;

  useEffect(() => {
    sequenceStoreData.setSequenceData(sequenceData);
  }, []);

  const location = useLocation();
  let isSharedFolder = location.pathname.indexOf("shared") !== -1;

  return (
    (sequenceData?.length &&
      sequenceData.map((item, index) => {
        if (item) {
          const { id, name, isEdit, shareType, accessType } = item || {};

          return isEdit ? (
            <ChangeShareType
              key={index}
              save={save}
              seqName={name}
              seqNameFocus={seqNameFocus}
              sequenceNameAction={SequenceNameAction}
              seqNameCancelAction={seqNameCancelAction}
              sequenceAccessType={accessType}
              radioOnchangeAction={radioOnchangeAction}
              showCreateSeqLoader={showCreateSeqLoader}
              sequenceShareType={shareType}
              seqId={id}
              placeHolder={"Sequence Name"}
            />
          ) : (
            <SequenceRecord
              isSharedFolder={isSharedFolder}
              key={index}
              index={index}
              {...item}
              draggable
              {...props}
            />
          );
        }
      })) ||
    ""
  );
});

const SequenceRecord = (props = {}) => {
  const { inFreePlan = false, planName = "" } =
    userDetail.userFeatureFlag || {};
  const {
    index: key,
    id,
    folderId = "",
    clickCount,
    openCount,
    replyCount,
    contactsAdded,
    name,
    folderName,
    sentCount,
    folderIdFromURL,
    shareType,
    owner,
    accessType,
    memberId,
    createTs,
    steps = {},
    background = {},
    toggleAction,
    addSingleContact,
    moreOptionAction,
    editAction,
    cloneAction,
    deleteAction,
    maskAction,
    addBulkContactToSequence,
    taskEnabled = false,
    isSharedFolder = false,
    status = ""
  } = props || {};

  const isDraftStatus = status?.toLowerCase() === "draft";

  const isPausedSequence = !["paused", "suspended", "pause"].includes(
    background?.status?.toLowerCase() || status?.toLowerCase()
  );

  const getSequenceNavigationLink = (seqId = "") => {
    const selectedFolderDetails = Utils.getFolderDetails();
    const selectedFolderId = selectedFolderDetails?.folderId || "";
    if (seqId) {
      if (folderIdFromURL?.length) {
        return `${folderIdFromURL}/${seqId}`;
      } else if (selectedFolderId === sharedSeq?.toLowerCase()) {
        return `/sequences/shared/${seqId}`;
      } else {
        return `/sequences/all/${seqId}`;
      }
    }
  };

  let redirectUrl = getSequenceNavigationLink(id);

  const [showToggleTp, setToggleTp] = useState(false);

  const [{ isDragging }, dragRef] = useDrag({
    type: "moveSequence",
    item: {
      id,
      folderId,
      isSharedFolder,
      folderName: Utils.getFolderDetails().folderName
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  let opacity = isDragging ? 0.4 : 1;

  return (
    <div
      ref={dragRef}
      key={key}
      className="sequenceWrapper"
      style={{
        cursor: isDragging ? "grabbing" : "pointer",
        boxShadow: isDragging
          ? "0px 5px 12.4px 0.7px rgb(59 59 59 / 7%)"
          : "unset",
        opacity
      }}
    >
      <span className="material-icons-outlined dragHolder sequence">
        drag_indicator
      </span>
      {sequenceStoreData?.activeSequenceList?.length
        ? !sequenceStoreData?.activeSequenceList.includes(id) && (
            <UpgradeSequenceCard
              text="Basic Plan"
              plan="basicPlan"
              badgeSize="sm"
              type="newSequenceCard"
            />
          )
        : ""}
      {isDraftStatus ? (
        <ActivateSequenceToggle status={status} seqData={props} />
      ) : (
        <div
          className={`toogleSequence ${background?.loading ? "loading" : ""}`}
          onMouseOver={() => setToggleTp(key)}
          onMouseLeave={() => setToggleTp("")}
        >
          <div
            className={"toogleButton " + (background?.loading ? "loading" : "")}
          >
            <input
              type="checkbox"
              className="toggleCheckbox"
              checked={isPausedSequence}
              onChange={e =>
                (!background?.loading &&
                  toggleAction(
                    e,
                    id,
                    steps,
                    isPausedSequence,
                    redirectUrl,
                    props
                  )) ||
                {}
              }
            />
            <div className="knobs" />
            <div className="layer" />
          </div>
          {steps &&
            SequenceUtils.hasTask(steps) &&
            !taskEnabled &&
            !isPausedSequence && (
              <div className="wrapThunderIcon">
                <UpgradeIconTooltip
                  plan={PaywallUtils.getPlanName(planName, inFreePlan)}
                  type="pauseResumeSequence"
                  enableTooltip={true}
                  alignTooltip="leftTop"
                />
              </div>
            )}
          {showToggleTp === key && (
            <CustomTooltip
              text={
                background?.loading && background?.status
                  ? SEQ_BACKGROUND_MSG?.[background?.status?.toLowerCase()]
                  : !isPausedSequence
                  ? "Activate sequence"
                  : "Pause sequence"
              }
            />
          )}
        </div>
      )}
      <Link
        to={redirectUrl}
        data-foldername={folderName}
        id={id}
        className={key + "dataRow sequenceDataRow"}
      >
        <div className={key + "dataColumn sequenceDataColumn name"}>
          {Utils.getEntityName(shareType, accessType, owner, name, memberId)}
        </div>
        <div className={key + "dataColumn sequenceDataColumn contacts"}>
          {Utils.formatNumberToStringInUSFormat(contactsAdded) || "0"}
        </div>
        <div
          className={key + "dataColumn sequenceDataColumn openRate percentage"}
        >
          <i className="fa fa-circle dot" aria-hidden="true"></i>
          <span>
            {typeof openCount !== "undefined" && openCount && sentCount
              ? Utils.getPercentage(openCount, sentCount, 1) + "%"
              : "0%"}
          </span>
        </div>
        <div
          className={key + "dataColumn sequenceDataColumn clickRate percentage"}
        >
          <i className="fa fa-circle dot" aria-hidden="true"></i>
          <span>
            {typeof clickCount !== "undefined" && clickCount && sentCount
              ? Utils.getPercentage(clickCount, sentCount, 1) + "%"
              : "0%"}
          </span>
        </div>
        <div
          className={key + "dataColumn sequenceDataColumn replyRate percentage"}
        >
          <i className="fa fa-circle dot" aria-hidden="true"></i>
          <span>
            {typeof replyCount != "undefined" && replyCount && sentCount
              ? Utils.getPercentage(replyCount, sentCount, 1) + "%"
              : "0%"}
          </span>
        </div>

        <div className={key + "dataColumn sequenceDataColumn updateTs"}>
          <span>
            {typeof createTs != "undefined" && createTs
              ? Utils.formatDate(createTs)
              : "--"}
          </span>
        </div>
        <div className="iconCont">
          <div
            className={`addContacts icon ${key < 4 ? "first" : ""}`}
            id={id}
            title="Add Contacts"
          >
            <AddContactDropDown
              addSingleContact={addSingleContact}
              addBulkContact={addBulkContactToSequence}
              showButton={false}
              id={id}
              icon={
                <i className="material-icons-outlined icon-hover-btn">
                  person_add
                </i>
              }
              allowContactsUpload={
                steps &&
                ((Object.keys(steps)?.length &&
                  SequenceUtils.hasTask(steps) &&
                  taskEnabled) ||
                  !SequenceUtils.hasTask(steps))
              }
              allowSequence={false}
              pageType="sequences"
              selectedSeq={props}
              constructRedirectLink={true}
              folderId={Utils.getFolderDetails()?.folderId}
              scheduleWindow={() =>
                scheduleDataStore.getDefaultScheduleData(
                  props?.scheduleWindowId
                )
              }
              sequence={props}
            />
          </div>
          <MoreOptions
            moreOptionAction={moreOptionAction}
            id={id}
            keyVal={key}
            shareType={shareType}
            accessType={accessType}
            editAction={editAction}
            name={name}
            cloneAction={cloneAction}
            deleteAction={deleteAction}
            folderName={folderName}
            maskAction={maskAction}
          />
        </div>
      </Link>
    </div>
  );
};

const MoreOptions = props => {
  const {
    moreOptionAction,
    id,
    keyVal,
    editAction,
    name,
    cloneAction,
    deleteAction,
    folderName,
    maskAction,
    shareType = "",
    accessType = ""
  } = props;

  let {
    trialEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const planType = trialEnabled
    ? "Trial"
    : inFreePlan || planName?.toLowerCase() === "free"
    ? "Free"
    : planName;

  return (
    <div
      className="moreOption sequenceDataColumn icon"
      onClick={e => moreOptionAction(e)}
      data-id={id}
    >
      <i
        className="material-icons-outlined icon-hover-btn"
        title="More Options"
      >
        more_vert
      </i>
      <div
        data-key={keyVal}
        className={
          keyVal < 4
            ? "moreOptionCont animatedFast animatedDropDown first"
            : "moreOptionCont animatedFast animatedDropDown"
        }
        data-id={id}
      >
        <div
          className="option edit"
          onClick={e => editAction(e, id, name)}
          id={id}
        >
          <i
            style={{ fontSize: "16px" }}
            className="material-icons-outlined list-icon editIcon"
          >
            edit
          </i>
          Edit
        </div>
        <div
          className="option clone"
          onClick={e =>
            cloneAction(
              e,
              id,
              name,
              shareType === "PRIVATE" ? shareType : accessType
            )
          }
          id={id}
        >
          <span className="cloneIcon">
            <CloneIcon />
          </span>
          Clone
        </div>
        {planType?.toLowerCase() !== "free" && (
          <div
            className="option delete"
            id={id}
            onClick={e => deleteAction(e, id)}
            data-folder={folderName}
          >
            <span className="deleteIcon">
              <DeleteIcon />
            </span>
            Delete
          </div>
        )}
      </div>
      <div className="moreOptionMask" onClick={e => maskAction(e)} />
    </div>
  );
};

export default withRouter(observer(SequenceTable));
export { SequenceTable };
