import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
import { ReactComponent as CheckMark } from "Assets/svg/discover/checkMark.svg";
import { ReactComponent as CopyButton } from "Assets/svg/discover/copy.svg";
import { ReactComponent as MailButton } from "Assets/svg/discover/mail.svg";
import { ReactComponent as MobileIcon } from "Assets/svg/discover/mobile.svg";
import { Utils } from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { toasterState } from "../toaster";
import { getContactPurchasePayload } from "Pages/Dashboard/dataSearch/DataSearchUtils";

const FoundContactPopup = () => {
  const history = useHistory();
  const [popupState, setPopupState] = useState(false);
  const [singlePurchaseContactName, setSinglePurchaseContactName] =
    useState("");
  const [singlePurchaseContactMail, setSinglePurchaseContactMail] =
    useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const closePopup = () => {
    setPopupState(false);
    dataSearchState.closePopup();
  };

  const copyEmail = (val = "", msg = "", type = "") => {
    if (val) {
      Utils.mixpanelTrack(
        MXP_EVENT[
          type === "email" ? "COPY_EMAIL_CLICKED" : "COPY_PHONE_CLICKED"
        ],
        {
          pageType: PAGE_TYPE.discover
        }
      );
      Utils.copyToClipboard(val);
      toasterState.setToastMsg(msg, "success");
    }
  };

  const handleGetPhoneNumber = async () => {
    setPopupState(false);
    dataSearchState.setIsFindPhoneEmailClicked(true);
    const payload = getContactPurchasePayload(
      [dataSearchState?.contactId],
      null,
      "phone"
    );
    dataSearchState.setFindDataType("phone");
    Utils.mixpanelTrack(MXP_EVENT.FIND_PHONE_NUMBER_CLICKED, {
      pageType: PAGE_TYPE.discover,
      type: "Upgrade V2.0",
      contactName: dataSearchState?.singlePurchaseContactName,
      companyName: dataSearchState?.contactCompanyName
    });
    await dataSearchState.purchaseSingleContact(payload, history);
    setTimeout(() => {
      dataSearchState.setIsFindPhoneEmailClicked(false);
    }, 1000);
  };

  useEffect(() => {
    setSinglePurchaseContactMail(dataSearchState?.singlePurchaseContactMail);
  }, [dataSearchState?.singlePurchaseContactMail]);

  useEffect(() => {
    setSinglePurchaseContactName(dataSearchState?.singlePurchaseContactName);
  }, [dataSearchState?.singlePurchaseContactName]);

  useEffect(() => {
    setPopupState(dataSearchState?.foundData ? true : false);
  }, [dataSearchState?.foundData]);

  useState(() => {
    setMobileNumber(dataSearchState?.mobilePhone);
  }, [dataSearchState?.mobilePhone]);

  return (
    popupState && (
      <div className="foundMailPopupWrapper">
        <div className="foundMailContactContent">
          <div className="closeButtonWrapper">
            <div className="closeButton" onClick={() => closePopup()}>
              <i className="material-icons closeIcon">close</i>
            </div>
          </div>
          <div className="headerData">
            <div className="fetchedData">{`${singlePurchaseContactName}’s contact details `}</div>
          </div>
          <div className="fetchProgress">
            <div className="checkMark">
              <CheckMark />
            </div>
            <div className="contactDataContainer">
              <div className="mailDataContainer">
                <div className="mailIcon">
                  <MailButton />
                </div>
                <div className="mailId">{singlePurchaseContactMail}</div>
                <div
                  className="copyIcon"
                  onClick={() =>
                    copyEmail(
                      singlePurchaseContactMail,
                      "Email address copied successfully.",
                      "email"
                    )
                  }
                >
                  <CopyButton />
                </div>
              </div>
              <div className="phoneDataContainer">
                <div className="phoneIcon">
                  <MobileIcon />
                </div>
                {mobileNumber ? (
                  <div className="phoneNumber found">{mobileNumber}</div>
                ) : (
                  <div className="phoneNumber">**************</div>
                )}
                {mobileNumber && (
                  <div
                    className="copyIcon"
                    onClick={() =>
                      copyEmail(
                        mobileNumber,
                        "Mobile number copied successfully.",
                        "phone"
                      )
                    }
                  >
                    <CopyButton />
                  </div>
                )}
                {!mobileNumber && (
                  <div
                    className="viewPhoneNumberContainer"
                    onClick={() => handleGetPhoneNumber()}
                  >
                    <div className="viewPhoneNumberButton">
                      <div className="viewPhoneNumber">Find Phone Number</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="gotItButtonContainer">
            <div className="gotItButtonWrapper" onClick={() => closePopup()}>
              <div className="gotItButton">Got it</div>
            </div>
          </div>
        </div>
        <div className="confirmation-popup-mask" />
      </div>
    )
  );
};

export default FoundContactPopup;
