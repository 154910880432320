/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import { snoozeTaskPopupstate } from "./snoozeTaskPopup";
import { ReactComponent as PhoneIcon } from "Assets/svg/call.svg";
import { ReactComponent as LinkedInIcon } from "Assets/svg/linkedin.svg";
import { userDetail } from "Stores/userDetail";
import taskStoreData from "Stores/taskData";
import DialerServiceData from "Stores/DialerServiceStore";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import globalCallState from "Stores/GlobalCallState";
import filterComponentState from "../FilterComponent/filterComponentState";
import { MP_EVENT } from "Static/constant";
import ButtonComponent from "Components/common/Button";
import DialerLogsAfterCallEndPopup, {
  dialerLogsPopupState,
  defaultDialerLogSettings
} from "Components/DailerService/DialerLogsAfterCallEndPopup";
import {
  checkTeamSubscriptionActive,
  fetchConnectedEmails
} from "Utils/commonAPI";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import SiblingButton from "./SiblingButton";
import TaskUtils from "Utils/TaskUtils";
import InboxUtils from "Utils/InboxUtils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import NavTaskButton from "./NavTaskButton";
import EmailTaskComponent from "./EmailTaskComponent";

const PerformTaskOperation = observer((props = {}) => {
  const {
    selectedTaskNumber = 1,
    isNextBtnEnabled = false,
    isPreviousBtnEnabled = false,
    data = {},
    setSelectedTaskFn = () => {},
    markSelectedTaskFn = () => {},
    markSkippedTaskFn = () => {},
    taskDetails = {}
  } = props || {};

  const {
    sequenceEmail = false,
    inboxId = "",
    existingfromAddress: fromAddress = ""
  } = taskDetails || {};

  let currentTaskId = data?.id || "";

  const taskType = data?.taskType?.toLowerCase();
  const toAddress = data?.email || "";
  const [skipConfirmation, setSkipConfirmation] = useState("");
  const [isCompletedTask, setCompletedTask] = useState(false);
  const [isSkippedTask, setSkippedTask] = useState(false);

  const inboxList = InboxUtils.getActiveConnectedInbox() || [];

  const constructFields = (response = {}) => {
    currentTaskId = response?.id || "";
    let { status = "" } = response || {};
    if (status) {
      setCompletedTask(
        ["scheduled", "completed"].includes(status?.toLowerCase())
      );
      setSkippedTask(status?.toLowerCase() === "skipped");
    }
  };

  const ScheduleTask = () => {
    const validateBlockedDomain = () => {
      let strArr = toAddress?.split("@") || [];
      if (
        strArr?.length > 1 &&
        userDetail?.blockedDomainList?.includes(strArr?.[1])
      ) {
        confirmationPopupState.setPopupValues({
          title: "Are you sure",
          content:
            "You cannot execute tasks/calls for contacts with ‘do not contact’ stage",
          needCancelBtn: false,
          actionBtnText: "Ok",
          callback: closePopup
        });
        confirmationPopupState.setShowPopup(true);
        return true;
      }
      return false;
    };

    const closePopup = () => {
      confirmationPopupState.setShowPopup(false);
    };

    const showSnoozeTaskPopup = async () => {
      if (await checkTeamSubscriptionActive()) {
        if (TaskUtils.isFileUploadingComplete()) {
          if (!window.loading) {
            const snoozeObj = { callback: snoozeTaskCbk };
            snoozeTaskPopupstate.setPopupValues(snoozeObj);
            snoozeTaskPopupstate.setShowPopup(true);
          }
        }
      }
    };

    const snoozeTaskThroughApi = payload => {
      const config = {
        url: URLS.snoozeTask,
        method: "POST",
        data: payload
      };
      return makeApi(config);
    };

    const handleSnoozeSuccess = () => {
      toasterState.setToastMsg("Task rescheduled successfully", "success");
      localStorage && localStorage.removeItem("selectedContact");
    };

    const handleSnoozeFailure = response => {
      Utils.showApiFailureResponse(response);
    };

    const snoozeTaskCbk = async (selectedSnoozeTime = {}) => {
      if (selectedSnoozeTime?.key) {
        toasterState.setToastMsg("Please wait...", "info");
        const mapData = {
          id: currentTaskId,
          type:
            taskType?.toLowerCase() === "manualemail"
              ? "manualEmail"
              : taskType?.toLowerCase() === "customtask"
              ? "customTask"
              : taskType,
          email: toAddress
        };
        const selectedHour = selectedSnoozeTime?.key || "";
        const scheduleTime =
          new Date().getTime() + selectedHour * 60 * 60 * 1000;
        mapData.dueDateString = Utils.createScheduleDate(
          new Date(scheduleTime)
        );
        mapData.timeZone = Utils.getCurrentUserTimeZone();
        mapData.attachments = taskStoreData?.taskAttachments || [];
        try {
          const response = await snoozeTaskThroughApi(mapData);
          if (response?.status === 200) {
            handleSnoozeSuccess(response);
          } else {
            handleSnoozeFailure(response);
          }
        } catch (e) {
          handleSnoozeFailure();
        }
      }
    };

    const handleTaskFailure = response => {
      Utils.showApiFailureResponse(response);
    };

    const handleTaskSuccess = (inputVal = {}) => {
      toasterState.setToastMsg("Task completed successfully", "success");
      markSelectedTaskFn(currentTaskId, {
        ...inputVal,
        bodyContent: inputVal?.bodyContent || inputVal?.content || "",
        attachments: inputVal?.attachments || []
      });
      fileAttachmentStore.setTaskTotalFileSizeUploaded(0);
      fileAttachmentStore.setPerformTaskFileAttachments([]);
      setSelectedTaskFn("complete");
    };

    const completeTaskThroughApi = (data = {}) => {
      const config = {
        url: URLS.markAsComplete,
        method: "POST",
        data
      };
      return makeApi(config);
    };

    const skipTaskThroughApi = data => {
      const config = {
        url: URLS.skipSequenceStage,
        method: "POST",
        data
      };
      return makeApi(config);
    };

    const handleSkipTaskSuccess = (response = {}) => {
      const { data = {} } = response || {};
      markSkippedTaskFn(currentTaskId, null);
      setSelectedTaskFn("next");
      let msg = data?.nextTask
        ? "Step skipped and next step in sequence scheduled"
        : "Step skipped and sequence completed";
      toasterState.setToastMsg(msg, "success");
    };

    const confirmSkipTask = () => {
      confirmationPopupState.setPopupValues({
        content: "Are you sure you want to skip this step?",
        actionBtnText: "Yes",
        callback: skipTaskAction,
        loadingMsg: "Skipping steps. Please wait..."
      });
      confirmationPopupState.setShowPopup(true);
    };

    const skipTaskAction = async () => {
      if (await checkTeamSubscriptionActive()) {
        const tempObj = {
          id: currentTaskId
        };
        try {
          const response = await skipTaskThroughApi(tempObj);
          if (response) {
            confirmationPopupState.setShowPopup(false);
            const { success = false } = response?.data || {};
            if (response?.status === 200) {
              success
                ? handleSkipTaskSuccess(response)
                : toasterState.setToastMsg(
                    "Failed to move to next step. Please try again later",
                    "failure"
                  );
            } else {
              handleTaskFailure(response);
            }
          }
        } catch (e) {
          handleTaskFailure();
        }
      }
    };

    const LinkedInComponent = () => {
      const [btnLoader, setBtnLoader] = useState(false);

      const markAsComplete = async (event, inputVal, type) => {
        Utils.preventDefaultFn(event);
        if (!validateBlockedDomain()) {
          if (await checkTeamSubscriptionActive()) {
            const tempObj = {
              id: currentTaskId,
              type: type,
              email: toAddress,
              timeZone: Utils.getCurrentUserTimeZone()
            };
            try {
              setBtnLoader(true);
              window.loading = true;
              const response = await completeTaskThroughApi(tempObj);
              setBtnLoader(false);
              window.loading = false;
              if (
                response?.status === 200 ||
                response?.response?.status === 200
              ) {
                Utils.mixpanelTrack(MP_EVENT.TK_COMPLETED, {
                  taskType: tempObj.type,
                  pageType: "Start Task"
                });
                handleTaskSuccess(response);
              } else {
                handleTaskFailure(response);
              }
            } catch (e) {
              handleTaskFailure();
            }
          }
        }
      };

      const linkedinTypeText = {
        LI_SEND_CONNECTION_REQUEST: {
          title: "LinkedIn - Send Connection Request",
          btnText: "Send connection request"
        },
        LI_VIEW_PROFILE: {
          title: "LinkedIn - View Profile",
          btnText: "View profile"
        },
        LI_SEND_MESSAGE: {
          title: "LinkedIn - Send Message",
          btnText: "Send a message"
        },
        LI_INTERACT_WITH_POST: {
          title: "LinkedIn - Interact With a Post",
          btnText: "View activity feed"
        }
      };

      const sendMsgToContentScript = data => {
        window.parent.postMessage(
          { data: JSON.stringify(data), type: "LINKEDIN_TASK" },
          "*"
        );
      };

      const getCurrentTask = () => {
        return (
          taskStoreData?.taskData?.tasks?.find(
            item => item?.task?.id === currentTaskId
          ) || ""
        );
      };

      const findInLinkedIn = () => {
        const liUrl = data?.linkedinId || "https://www.linkedin.com";
        const regex = /^https?:/;
        let linkedinUrl = !regex.test(liUrl) ? `https://${liUrl}` : liUrl;
        if (userDetail?.pluginInstalled) {
          let tasks = getCurrentTask();
          const {
            currentPage = 0,
            totalTasks = 0,
            totalPages = 0
          } = taskStoreData?.taskData || {};
          sendMsgToContentScript({
            tasks,
            selectedTaskNo: selectedTaskNumber,
            currentPage: currentPage || 1,
            totalTasks,
            totalPages,
            status: taskStoreData?.status?.toLowerCase(),
            filter: taskStoreData?.filter?.toLowerCase(),
            dateFilterType: taskStoreData?.dateFilterValue || "all",
            linkedinUrl,
            currentTaskId: taskStoreData?.currentTaskId,
            allFiltersData: {
              sequenceIds:
                filterComponentState.filterComponentData["sequenceIds"] || [],
              tagIds: filterComponentState.filterComponentData["tagIds"] || [],
              contactStage:
                filterComponentState.filterComponentData["contactStage"] || [],
              contactFields:
                filterComponentState.filterComponentData["contactFields"] || [],
              dateFilter:
                filterComponentState.filterComponentData["dateFilter"] || []
            }
          });
        } else {
          const w = 600;
          const h = 720;
          const left = window.screen.width / 2 - w / 2;
          const top = window.screen.height / 2 - h / 2;
          let params = `directories=0,titlebar=0,scrollbars=0,resizable=0,status=0,location=0,toolbar=0,menubar=0,width=${w},height=${h},left=${left},top=${top}`;
          window.open(linkedinUrl, "", params);
        }
      };

      const isSkipStageEnabled = () => {
        return !btnLoader && !data?.customTask;
      };

      return (
        <>
          <div className="linkedInTask">
            <div className="taskTypeCont">
              <label className="taskLabel">Task : </label>
              <div className="taskType">
                {linkedinTypeText[data?.linkedInTaskType]?.title || ""}
              </div>
            </div>
            <div className="notesCont">
              <label className="notesLabel">Notes :</label>
              {data?.note && (
                <div className="inputFieldCont editableAreaWrapper">
                  <div className="editableArea">{data?.note}</div>
                </div>
              )}
            </div>
            {!isCompletedTask && !isSkippedTask && (
              <div className="taskButtonWrapper">
                <div
                  className="taskButton whiteButton btn"
                  onClick={findInLinkedIn}
                >
                  <span className="svgIcon">
                    <LinkedInIcon />
                  </span>
                  <span className="taskButtonText">
                    {linkedinTypeText[data?.linkedInTaskType]?.btnText || ""}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="actionCont">
            <div className="btnWrapper">
              <div className={`sendbtnCont ${btnLoader ? "loading" : ""}`}>
                {isCompletedTask ? (
                  <CompletedText />
                ) : isSkippedTask ? (
                  <div className="completedBtn">
                    <i className="material-icons tickIcon">check</i>
                    <span className="completedButton">Skipped</span>
                  </div>
                ) : (
                  <>
                    <ButtonComponent
                      id="markComplete"
                      name="markComplete"
                      type="button"
                      className={isSkipStageEnabled() ? "hasSiblingBtn" : ""}
                      btnText={`${btnLoader ? "" : "Mark as Complete"}`}
                      icon={false}
                      click={e => markAsComplete(e, null, "linkedin")}
                      iconName=""
                    />
                    {btnLoader && <div className="knobs"></div>}
                    {isSkipStageEnabled() && (
                      <SiblingButton cbk={confirmSkipTask} />
                    )}
                  </>
                )}
              </div>
              {!isCompletedTask && !isSkippedTask && (
                <>
                  <div
                    className="snoozeCont whiteButton btn"
                    onClick={showSnoozeTaskPopup}
                  >
                    <i className="material-icons">snooze</i>
                    <span className="snoozeTxt">Snooze</span>
                  </div>
                </>
              )}
            </div>
            <NavTaskButton
              setSelectedTaskFn={setSelectedTaskFn}
              isPreviousBtnEnabled={isPreviousBtnEnabled}
              isNextBtnEnabled={isNextBtnEnabled}
              id={currentTaskId}
              pageType="PERFORM_TASK"
            />
          </div>
        </>
      );
    };

    const PhoneCallComponent = () => {
      const markAsCompleteFunctionality = async (data = {}) => {
        globalCallState.resetPopupValues();
        if (!validateBlockedDomain()) {
          if (await checkTeamSubscriptionActive()) {
            const {
              logContactDetail = false,
              callSid = "",
              callDispositionId = "",
              callOutcome,
              notes: callNotes = "",
              phoneNumber = ""
            } = data || {};
            if (callDispositionId) {
              const tempObj = {
                id: currentTaskId,
                type: "phone",
                phoneNumber: phoneNumber || dialerLogsPopupState.dialerNumber,
                email: toAddress,
                note: callNotes,
                callDispositionId,
                callOutcome,
                callSid,
                logContactDetail
              };
              window.loading = true;
              const response = await completeTaskThroughApi(tempObj);
              dialerLogsPopupState.setShowLogCallPopup(false);
              window.loading = false;
              dialerLogsPopupState.setDialerLogSettings(
                defaultDialerLogSettings
              );
              if (
                response?.status === 200 ||
                response?.response?.status === 200
              ) {
                Utils.mixpanelTrack(MP_EVENT.TK_COMPLETED, {
                  taskType: tempObj.type,
                  pageType: "Start Task"
                });
                handleTaskSuccess(response);
                return "completed";
              } else {
                handleTaskFailure(response);
                return "failure";
              }
            } else {
              toasterState.setToastMsg("Please select a call outcome", "fail");
            }
          }
        }
      };

      const openDailerServiceCard = async () => {
        if (!validateBlockedDomain()) {
          if (await checkTeamSubscriptionActive()) {
            let userData = userDetail.userFeatureFlag;
            if (userData.dialerEnabled !== "") {
              if (userData.dialerEnabled) {
                if (DialerServiceData?.dialerDefaultNo !== "purchaseNumber") {
                  Utils.mixpanelTrack(MXP_EVENT.MAKE_CALL_CLICKED, {
                    pageType: "Tasks Page",
                    type: "Call button"
                  });
                  DialerServiceData.getDailerToken();
                  dialerLogsPopupState.setContactData(
                    globalCallState.popupValues.contactData
                  );
                  globalCallState.setPopupValues({
                    ...globalCallState.popupValues,
                    ...{
                      taskId:
                        currentTaskId || taskStoreData?.currentTaskId || "",
                      contactData: globalCallState.popupValues.contactData,
                      dialerLogAfterCallEnd: false,
                      performCbkFunction: performTaskCbk,
                      isContactCard: false,
                      pageType: "tasks"
                    }
                  });
                  globalCallState.setShowCallSlider(true);
                } else {
                  Utils.showUpgradeSettingsPopup("dialerPurchase", true, true);
                }
              } else {
                Utils.showUpgradeSettingsPopup("dialer", true, true);
              }
            } else {
              toasterState.setToastMsg("Please wait...", "info");
            }
          }
        }
      };

      const markAsComplete = () => {
        if (!validateBlockedDomain()) {
          dialerLogsPopupState.setShowLogCallPopup(true);
        }
      };

      const isSkipStageEnabled = () => {
        return data && !data.phone;
      };

      const performTaskCbk = () => {
        globalCallState.setShowCallSlider(false);
        dialerLogsPopupState.setShowLogCallPopup(true);
      };

      useEffect(() => {
        dialerLogsPopupState.setContactData(data);
        dialerLogsPopupState.setShowLogCallPopup(false);
      }, [data]);

      return (
        <>
          <DialerLogsAfterCallEndPopup
            additionalInfo={
              taskStoreData?.taskData?.tasks?.[selectedTaskNumber - 1]
            }
            markAsCompleteFunctionality={markAsCompleteFunctionality}
            {...dialerLogsPopupState.dialerLogSettings}
            isFromTask={true}
          />
          <div className="phoneCallTask">
            <div className="taskTypeCont">
              <label className="taskLabel">Task : </label>
              <div className="taskType">Make a call</div>
            </div>
            <div className="notesCont">
              <label className="notesLabel">Notes :</label>
              <div className="inputFieldCont editableAreaWrapper">
                <div className="editableArea">{data.note}</div>
              </div>
            </div>
            <div className="taskButtonWrapper">
              {!isCompletedTask && !isSkippedTask && (
                <div
                  className="taskButton whiteButton btn"
                  onClick={openDailerServiceCard}
                >
                  <span className="svgIcon phoneIcon">
                    <PhoneIcon />
                  </span>
                  <span className="taskButtonText">
                    {`Call ${data?.firstName || ""}`}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="actionCont">
            <div className="btnWrapper">
              <div className="sendbtnCont">
                {isCompletedTask ? (
                  <CompletedText />
                ) : isSkippedTask ? (
                  <div className="completedBtn">
                    <i className="material-icons tickIcon">check</i>
                    <span className="completedButton">Skipped</span>
                  </div>
                ) : (
                  <>
                    <ButtonComponent
                      id="markComplete"
                      name="markComplete"
                      type="button"
                      className={isSkipStageEnabled() ? "hasSiblingBtn" : ""}
                      btnText="Mark as Complete"
                      icon={false}
                      click={e => markAsComplete(e, null, "phone")}
                      iconName="  "
                    />
                    {isSkipStageEnabled() && (
                      <SiblingButton cbk={confirmSkipTask} />
                    )}
                  </>
                )}
              </div>
              {!isCompletedTask && !isSkippedTask && (
                <>
                  <div
                    className="snoozeCont whiteButton btn"
                    onClick={showSnoozeTaskPopup}
                  >
                    <i className="material-icons">snooze</i>
                    <span className="snoozeTxt">Snooze</span>
                  </div>
                </>
              )}
            </div>
            <NavTaskButton
              setSelectedTaskFn={setSelectedTaskFn}
              isPreviousBtnEnabled={isPreviousBtnEnabled}
              isNextBtnEnabled={isNextBtnEnabled}
              id={currentTaskId}
              pageType="PERFORM_TASK"
            />
          </div>
        </>
      );
    };

    const CustomTaskComponent = () => {
      const [btnLoader, setBtnLoader] = useState(false);

      const markAsComplete = async (event, _, type) => {
        Utils.preventDefaultFn(event);
        if (!validateBlockedDomain()) {
          if (await checkTeamSubscriptionActive()) {
            const tempObj = {
              id: currentTaskId,
              type: type,
              email: toAddress,
              timeZone: Utils.getCurrentUserTimeZone()
            };
            try {
              setBtnLoader(true);
              window.loading = true;
              const response = await completeTaskThroughApi(tempObj);
              setBtnLoader(false);
              window.loading = false;
              if (
                response?.status === 200 ||
                response?.response?.status === 200
              ) {
                Utils.mixpanelTrack(MP_EVENT.TK_COMPLETED, {
                  taskType: tempObj.type,
                  pageType: "Start Task"
                });
                handleTaskSuccess(response);
              } else if (response?.response?.status === 401) {
                toasterState.setToastMsg(
                  "Failed to send email. Please reconnect your inbox and try again.",
                  "fail"
                );
                await fetchConnectedEmails();
              } else {
                handleTaskFailure(response);
                await fetchConnectedEmails();
              }
            } catch (e) {
              handleTaskFailure();
            }
          }
        }
      };

      const isSkipStageEnabled = () => {
        return data && !data.customTask;
      };

      useEffect(() => {
        dialerLogsPopupState.setContactData(data);
      }, [data]);

      return (
        <>
          <div className="customTask">
            <div className="taskTypeCont">
              <label className="taskLabel">Task : </label>
              <div className="taskType">Custom Task</div>
            </div>
            <div className="notesCont">
              <label className="notesLabel">Notes :</label>
              <div className="inputFieldCont editableAreaWrapper">
                <div className="editableArea">{data?.note || "--"}</div>
              </div>
            </div>
          </div>
          <div className="actionCont">
            <div className="btnWrapper">
              <div className={`sendbtnCont ${btnLoader ? "loading" : ""}`}>
                {isCompletedTask ? (
                  <CompletedText />
                ) : isSkippedTask ? (
                  <div className="completedBtn">
                    <i className="material-icons tickIcon">check</i>
                    <span className="completedButton">Skipped</span>
                  </div>
                ) : (
                  <>
                    <ButtonComponent
                      id="markComplete"
                      name="markComplete"
                      type="button"
                      className={isSkipStageEnabled() ? "hasSiblingBtn" : ""}
                      btnText={`${btnLoader ? "" : "Mark as Complete"}`}
                      icon={false}
                      click={e => markAsComplete(e, null, "customTask")}
                      iconName="  "
                    />
                    {btnLoader && <div className="knobs"></div>}
                    {isSkipStageEnabled() && (
                      <SiblingButton cbk={confirmSkipTask} />
                    )}
                  </>
                )}
              </div>
              {!isCompletedTask && !isSkippedTask && (
                <>
                  <div
                    className="snoozeCont whiteButton btn"
                    onClick={showSnoozeTaskPopup}
                  >
                    <i className="material-icons">snooze</i>
                    <span className="snoozeTxt">Snooze</span>
                  </div>
                </>
              )}
            </div>
            <NavTaskButton
              setSelectedTaskFn={setSelectedTaskFn}
              isPreviousBtnEnabled={isPreviousBtnEnabled}
              isNextBtnEnabled={isNextBtnEnabled}
              id={currentTaskId}
              pageType="PERFORM_TASK"
            />
          </div>
        </>
      );
    };

    const LoadingComponent = () => {
      return (
        <div
          className="emailTask"
          style={{ height: "100%", position: "relative" }}
        >
          {Array.from({ length: 3 }, (value, index) => (
            <div className="userInputCont addressCont" key={index}>
              <span className="labelName linear-background"></span>
              <span className="contactName linear-background"></span>
            </div>
          ))}
          <div className="editorLoader">
            <span className="contactName linear-background"></span>
          </div>
          <div className="actionContLoader">
            {Array.from({ length: 3 }, (value, index) => (
              <span className="actionBtn linear-background" key={index}></span>
            ))}
          </div>
        </div>
      );
    };

    return (
      <div className="scheduleTask">
        {!props.loading ? (
          taskType === "email" || taskType === "manualemail" ? (
            <EmailTaskComponent
              data={data}
              fromAddress={fromAddress}
              sequenceEmail={sequenceEmail}
              inboxId={inboxId}
              toAddress={toAddress}
              currentTaskId={currentTaskId}
              skipConfirmation={skipConfirmation}
              setSkipConfirmation={setSkipConfirmation}
              validateBlockedDomain={validateBlockedDomain}
              completeTaskThroughApi={completeTaskThroughApi}
              handleTaskFailure={handleTaskFailure}
              markSkippedTaskFn={markSkippedTaskFn}
              setSelectedTaskFn={setSelectedTaskFn}
              inboxList={inboxList}
              isCompletedTask={isCompletedTask}
              isSkippedTask={isSkippedTask}
              showSnoozeTaskPopup={showSnoozeTaskPopup}
              isPreviousBtnEnabled={isPreviousBtnEnabled}
              isNextBtnEnabled={isNextBtnEnabled}
              handleTaskSuccess={handleTaskSuccess}
            />
          ) : taskType === "linkedin" ? (
            <LinkedInComponent />
          ) : taskType === "phone" ? (
            <PhoneCallComponent />
          ) : taskType === "customtask" ? (
            <CustomTaskComponent />
          ) : (
            ""
          )
        ) : (
          <LoadingComponent />
        )}
      </div>
    );
  };

  const CompletedText = () => {
    return (
      <div className="completedBtn">
        <i className="material-icons tickIcon">check</i>
        <span className="completedButton">Completed</span>
      </div>
    );
  };

  const updateExistingAttchments = (list = []) => {
    let totalSize = 0;
    if (list?.length > 0) {
      [...list].forEach(item => {
        const { attachmentSizeInKB = "" } = item || {};
        totalSize += attachmentSizeInKB || 0;
      });
    }
    fileAttachmentStore.setTaskTotalFileSizeUploaded(
      Math.ceil(totalSize / 1024) || 0
    );
    taskStoreData.setTaskAttachments(list);
  };

  useEffect(() => {
    currentTaskId = data?.id;
    constructFields(data);
    let tempAttachments =
      data?.status === "COMPLETED"
        ? taskStoreData?.taskAttachments?.length > 0
          ? taskStoreData?.taskAttachments
          : data?.attachments
        : data?.attachments;
    updateExistingAttchments(tempAttachments || []);
  }, [data]);

  return (
    <div className="performTaskSection">
      <div className="innerCont">
        <ScheduleTask />
      </div>
    </div>
  );
});

export default withRouter(PerformTaskOperation);
export { PerformTaskOperation };
