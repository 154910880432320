import React, { useEffect, useState } from "react";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
import { ReactComponent as ContactNotFound } from "Assets/svg/discover/phoneNotFound.svg";

const PhoneNotFound = () => {
  const [popupState, setPopupState] = useState(false);

  const closePopup = () => {
    dataSearchState.closePopup();
  };

  useEffect(() => {
    setPopupState(!dataSearchState?.isMobileNumberNotFound);
  }, [dataSearchState?.isMobileNumberNotFound]);

  return (
    popupState && (
      <div className="phoneNotFoundPopupWrapper">
        <div className="phoneNotFoundContent">
          <div className="closeButtonWrapper">
            <div className="closeButton" onClick={() => closePopup()}>
              <i className="material-icons closeIcon">close</i>
            </div>
          </div>
          <div className="headerData">
            <div className="fetchedData">
              {`Unable to Find ${dataSearchState?.singlePurchaseContactName}’s Phone Number`}
            </div>
          </div>

          <div className="fetchProgress">
            <div className="contactNotFound">
              <ContactNotFound />
            </div>
            <div className="contactDataContainer">
              <div className="mailDataContainer">
                Try finding the phone number of a different contact.
              </div>
            </div>
          </div>
          <div className="continueToSearchContainer">
            <div
              className="continueToSearchButtonWrapper"
              onClick={() => closePopup()}
            >
              <div className="gotItButton">Ok</div>
            </div>
          </div>
        </div>
        <div className="confirmation-popup-mask" />
      </div>
    )
  );
};
export default PhoneNotFound;
