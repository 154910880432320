import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "../common/toaster";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
const { observable, action } = require("mobx");

class PhoneNumberStore {
  @observable phoneNumberLoading = false;

  @action
  setPhoneNumberLoading = (value = "") => {
    this.phoneNumberLoading = value;
  };

  @action
  purchasePhoneNumber = async (contactId = "") => {
    try {
      const config = {
        url: `${URLS.purchasePhoneNumber}/${contactId}`,
        method: "GET"
      };
      let response = await makeApi(config);
      const responseCode = +response?.data?.code;
      this.setPhoneNumberLoading(false);
      if ([200, 201, 404]?.includes(responseCode)) {
        return response?.data;
      } else {
        if ([202, 103, 104, 402, 105]?.includes(responseCode)) {
          const parsedContact = response?.data?.contact || {};
          const { firstName = "", lastName = "" } = parsedContact || {};
          const contactFirstName = JSON.stringify(firstName);
          const contactLastName = JSON.stringify(lastName);
          const truncatedContactFirstName = contactFirstName.slice(
            1,
            contactFirstName.length - 1
          );
          const truncatedContactLastName = contactLastName.slice(
            1,
            contactLastName.length - 1
          );
          dataSearchState.setSinglePurchaseContactName(
            [truncatedContactFirstName, truncatedContactLastName]
              .filter(Boolean)
              .join(" ")
          );
          dataSearchState.setIsNoPhoneCredits(true);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };
}

const phoneNumberStore = new PhoneNumberStore();

export { phoneNumberStore };
