import React, { useEffect, useState } from "react";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";

const FindContactPopup = () => {
  const [findDataType, setDataType] = useState("");
  const [singlePurchaseContactName, setSinglePurchaseContactName] =
    useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const [shouldStop, setShouldStop] = useState(false);

  useEffect(() => {
    if (dataSearchState?.isFindPhoneEmailClicked) {
      setIsAnimating(true);
      setShouldStop(false);
    } else {
      setTimeout(() => {
        setIsAnimating(false);
      }, 1000);
      setShouldStop(true);
    }
  }, [dataSearchState?.isFindPhoneEmailClicked]);

  useEffect(() => {
    setDataType(dataSearchState?.findDataType);
  }, [dataSearchState?.findDataType]);

  useEffect(() => {
    setSinglePurchaseContactName(dataSearchState?.singlePurchaseContactName);
  }, [dataSearchState?.singlePurchaseContactName]);

  return (
    <div className="findMailContactPopupWrapper">
      <div className="findMailContactContent">
        <div className="fetchContactData">
          {`Finding ${singlePurchaseContactName}'s ${
            findDataType.toLowerCase() === "email"
              ? `email address`
              : `phone number`
          }`}
        </div>
        <div className="fetchProgress">
          {`Hang tight! We're fetching ${singlePurchaseContactName}'s ${
            findDataType.toLowerCase() === "email"
              ? `email address`
              : `phone number`
          } for you...`}
        </div>
        <div className="container">
          <div
            className={`loading ${
              isAnimating ? "active" : shouldStop ? "stopping" : ""
            }`}
          ></div>
        </div>
      </div>
      <div className="confirmation-popup-mask" />
    </div>
  );
};
export default FindContactPopup;
