/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import onboarding from "../common/Onboarding";
import { ReactComponent as Onboard } from "../../Global/Header/Icons/onboard.svg";

const OnBoardingRightSelection = observer(props => {
  const GettingStartedButton = observer(props => {
    const [remainingFeatureCount, setRemainingFeatureCount] = useState(0);

    useEffect(() => {
      setRemainingFeatureCount(onboarding?.remainingFeatureCount);
    }, [onboarding?.remainingFeatureCount]);

    return (
      <div className="getStartedWrapper">
        {remainingFeatureCount > 0 && (
          <span className="remainingCount">{remainingFeatureCount}</span>
        )}
      </div>
    );
  });

  return (
    <div className="onboardingRightSelectionMainContainer">
      <Onboard className="onboardingRightSelectionIcon" />
      <GettingStartedButton />
    </div>
  );
});

export default OnBoardingRightSelection;
export { OnBoardingRightSelection };
