import React, { useEffect, useState } from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { sequenceStoreData } from "Stores/sequenceData";
import { toasterState } from "Components/common/toaster";
import Button from "Components/common/Button";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import InitialSchedule from "./InitialSchedule";
import MultiInboxSelection from "./MultiInboxSelection";
import SequenceUtils from "Utils/SequenceUtils";

class InitialSchedulePopupState {
  @observable showPopup = false;
  @observable defaultValues = {};
  @observable connectedInbox = {};
  @observable scheduleState = {};
  @observable cbk = () => {};
  @observable defaultProps = {};

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
  };

  @action
  setDefaultValues = (val = {}) => {
    this.defaultValues = val;
  };

  @action
  setConnectedInbox = (val = {}) => {
    this.connectedInbox = val;
  };

  @action
  setScheduleState = (val = {}) => {
    this.scheduleState = val;
  };

  @action
  setCbk = (val = () => {}) => {
    this.cbk = val;
  };

  @action
  setDefaultProps = (val = {}) => {
    this.defaultProps = val;
  };
}

const initialSchedulePopupState = new InitialSchedulePopupState();

const ConnectedInbox = observer(() => {
  const {
    sequence = {},
    sequenceId = "",
    inboxIds: connectedInboxIds,
    isSharedSequence,
    reassignInbox = false,
    ...remainingProps
  } = initialSchedulePopupState?.defaultProps || {};

  const [reAssignContact, setReAssignContact] = useState(reassignInbox);
  const [isInboxMappedToSequence, setIsInboxMappedToSequence] = useState(false);

  const cbk = () => {
    initialSchedulePopupState.setShowPopup(false);
  };

  useEffect(() => {
    if (typeof sequence?.inboxIds !== "undefined") {
      setIsInboxMappedToSequence(true);
    }
  }, [sequence?.inboxIds, userDetail?.connectedInboxes]);

  return (
    <MultiInboxSelection
      sequenceMemberId={sequence?.memberId}
      sequenceId={sequenceId}
      connectedInboxIds={connectedInboxIds}
      isSharedSequence={isSharedSequence}
      reAssignContact={reAssignContact}
      setReAssignContact={setReAssignContact}
      isInboxMappedToSequence={isInboxMappedToSequence}
      {...remainingProps}
      hasSubLabel={false}
      showReassignInbox={false}
      className="initialSchedulePopupInbox"
      cbk={cbk}
      showLimit={true}
      title="Your emails will be sent from below email accounts"
    />
  );
});

const InitialScheduleInfoPopup = observer(() => {
  const { sequence = {} } = initialSchedulePopupState?.defaultProps || {};
  const [buttonLoading, setButtonLoading] = useState(false);

  const closePopup = () => {
    if (initialSchedulePopupState?.defaultProps?.getSelectedSeqData) {
      initialSchedulePopupState.defaultProps.getSelectedSeqData();
    }
    initialSchedulePopupState.setShowPopup(false);
  };

  const handleActivateSequence = async () => {
    setButtonLoading(true);
    const inboxIds =
      sequenceStoreData?.selectedConnectedInbox?.length > 0
        ? sequenceStoreData?.selectedConnectedInbox?.map(item => item?.id)
        : [];
    const data = {
      ...initialSchedulePopupState?.scheduleState,
      id: initialSchedulePopupState?.defaultValues?.id,
      inboxIds
    };
    const config = {
      url: URLS.startSeq,
      method: "POST",
      data
    };
    let res = await makeApi(config);
    const { data: respData = {} } = res || {};
    const { code = "" } = respData || {};
    setButtonLoading(false);
    initialSchedulePopupState.setShowPopup(false);
    if (+code === 200) {
      initialSchedulePopupState.cbk();
      Utils.getOnboardingPopupDataAfterContactUpload();
      toasterState.setToastMsg("Sequence activated successfully.", "success");
    } else {
      if (+code === 403) {
        toasterState.setToastMsg(
          "Oops! You don't have access to start the sequence. Only the sequence owner can start the sequence.",
          "fail"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
    }
  };

  const handleDisableBtn = () => {
    if (SequenceUtils.checkEmailSteps(sequence)) {
      return !(
        initialSchedulePopupState?.defaultValues?.id &&
        sequenceStoreData?.selectedConnectedInbox?.length > 0 &&
        initialSchedulePopupState?.scheduleState?.scheduleWindowId
      );
    } else {
      return !(
        initialSchedulePopupState?.defaultValues?.id &&
        initialSchedulePopupState?.scheduleState?.scheduleWindowId
      );
    }
  };

  const activateSeqBtnProps = {
    id: "activateSequence",
    name: "activateSequence",
    btnText: "Activate Sequence",
    type: "button",
    className: `activateSequenceBtn ${buttonLoading ? "loading" : ""}`,
    click: handleActivateSequence,
    loader: buttonLoading,
    disabled: handleDisableBtn()
  };

  useEffect(() => {
    initialSchedulePopupState.setConnectedInbox(
      userDetail?.connectedInboxes?.[0] || {}
    );
  }, [userDetail?.connectedInboxes]);

  return (
    initialSchedulePopupState?.showPopup && (
      <>
        <div className="initialSchedulePopupWrapper">
          <div className="card animated customzoomIn">
            <div className="cardHeader">
              <div className="seqNameWrapper">
                <div className="seqName">
                  {`Activate ${initialSchedulePopupState?.defaultValues?.name}`}
                </div>
                <div className="desc">
                  Review inboxes, time zone & sending window before activating
                  the sequence.
                </div>
              </div>
              <i className="material-icons closePopup" onClick={closePopup}>
                close
              </i>
            </div>
            <div className="cardBody">
              <ConnectedInbox />
              <InitialSchedule
                scheduleWindowId={
                  initialSchedulePopupState?.defaultValues?.scheduleWindowId
                }
                id={initialSchedulePopupState?.defaultValues?.id}
              />
              <div className="actionBtn">
                <Button {...activateSeqBtnProps} />
              </div>
            </div>
          </div>
        </div>
        <div className="initialSchedulePopupOverlay" />
      </>
    )
  );
});

export { InitialScheduleInfoPopup, initialSchedulePopupState };
export default InitialScheduleInfoPopup;
